import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Aktuelles() {
  return (
    <>
      <div className="d-flex flex-column">
        <img
          src="img/LogoKollektivRDunkel.png"
          alt="Logo"
          style={{ maxWidth: "300px", margin: "auto" }}
        />
      </div>
      <div className="mt-3">
        <i>18.06.2023</i>
        <br />
        <p>
          Kollektiv R! <br></br>
          <br></br>
          Bis in den Herbst 2022 hinein waren wir noch Students For Future Augsburg. Jedoch waren wir aus verschiedenen Gründen nicht mehr glücklich mit dem Namen und der Ausrichtung unserer Gruppe. In den vergangenen Monaten haben wir uns Zeit genommen, um unsere bisherige politische Arbeit zu reflektieren und strategisch neu auszurichten. Am Ende dieses Prozesses angelangt, sind wir nun das Kollektiv R.
          <br></br>
          <br></br>
          Nachdem Fridays For Future seit 2019 auf die Klimakatastrophe aufmerksam gemacht hat, ist der realpolitische Erfolg in der Klimapolitik ausgeblieben. Wie auch soll sich in einem kapitalistischen System, was auf westlicher Dominanz in Form von wirtschaftlicher Ausbeutung und Unterdrückung beruht eine solch umfangreiche Transformation in wenigen Jahren vollziehen? Zu etabliert sind die Funktionsweisen des Wirtschafts- und Gesellschaftssystems. Märkte werden stetig liberalisiert, Menschen individualisieren sich zunehmend und entfernen sich von der Gemeinschaft. Während Menschen verständlicherweise Angst haben, ihren Wohlstand zu verlieren, ist es politischen Entscheidungsträger*innen schlicht nicht möglich, den notwendigen radikalen Wandel in Maßnahmen zu übersetzen - das lassen die Systemzwänge schlichtweg nicht zu.<br></br>
          <br></br>
          Für uns bedeutete diese Analyse einen radikalen Wandel unserer Strategie. Da das politische und wirtschaftliche System auf rassistischen, patriarchalen und kapitalistischen Verhaltensweisen aufbaut, lässt es sich innerhalb dessen nicht verändern. Deshalb machen wir nun Graswurzelarbeit an der Basis der Gesellschaft: Lokale, autonome Strukturen aufbauen, Menschen vernetzen und ihnen ihren Handlungsspielraum innerhalb der Gemeinschaft aufzeigen. Nur so können wir den Problemen von heute und morgen begegnen und autoritären und totalitären Tendenzen entgegenwirken!<br></br>
          <br></br>
          <br></br>
          Wenn du mehr über unsere Ausrichtung wissen willst, lies die folgenden Reiter: Awareness, Narrativ & Zukunftsvision, Demokratieverständnis & Macht und Hierarchie & Konsens!
        </p>
        <br />
      </div>
      
      <div className="d-flex flex-column">
        <img
          src="img/Lützi Lebt - Bild.png"
          alt="Lützi Lebt - Bild"
          style={{ maxWidth: "800px", margin: "auto" }}
        />
      </div>

      <br />
    </>
  );
}
