import { Link } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { NavbarItem } from "./NavbarItem";

export function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-xl">
        <Link className="navbar-brand" to={"/"}>
          <img
            src="img/LogoKollektivRHell.png"
            alt="Logo"
            style={{ height: "5em" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          style={{ border: "solid black" }}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div
            className="navbar-nav mx-auto mb-2 mb-lg-0"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "justify",
            }}
          >
            <div className="nav-bar-container">
              <li className="nav-item">
                <Link className="nav-link" to={"/"}>
                  <NavbarItem text="Aktuelles" />
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/awareness"}>
                  <NavbarItem text="Awareness" />
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/narrativ"}>
                  <NavbarItem text="Narrativ & Zukunftsvision" />
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/demokratie-macht"}>
                  <NavbarItem text="Demokratie & Macht" />
                </Link>
              </li>
            </div>
            <div className="nav-bar-container">
              <li className="nav-item">
                <Link className="nav-link" to={"/hierarchie-konsens"}>
                  <NavbarItem text="Hierarchie & Konsens" />
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/glossar-wissensspeicher"}>
                  <NavbarItem text="Glossar & Wissensspeicher" />
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/kontakt"}>
                  <NavbarItem text="Kontakt" />
                </Link>
              </li>
              <div style={{ display: "flex" }}>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="mailto:info@kollektivr.de"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-envelope-fill headerIcons"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.instagram.com/studentsforfuture_augsburg/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-instagram headerIcons"></i>
                  </a>
                </li>
              </div>
            </div>
          </div>

          {/* Header dropdown menu example */}
          {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/forderungen"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="headerText">Forderungen</span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/forderungen/#klimaneutral"
                      >
                        Klimaneutralität bis 2027
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/forderungen/#dekol">
                        Dekolonialisierung
                      </Link>
                    </li>
                  </ul>
                </li> */}
        </div>
      </nav>
    </>
  );
}
