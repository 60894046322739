export function WebsiteStatistik(): JSX.Element {
  return (
    <div>
      <div
        style={{ marginTop: "20px" }}
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://grafanawebsitestatistic.northeurope.cloudapp.azure.com:3000/d-solo/RK1XJEw4z/kollektiv-r?orgId=1&panelId=5" width="800" height="400" frameborder="0"></iframe>`,
        }}
      ></div>
      <div
        style={{ marginTop: "20px" }}
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://grafanawebsitestatistic.northeurope.cloudapp.azure.com:3000/d-solo/dl0UeEw4k/kollektiv-r-last-year?orgId=1&panelId=2" width="800" height="400" frameborder="0"></iframe>`,
        }}
      ></div>
    </div>
  );
}
