import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";

export function HierarchieUndKonsens() {
  return (
    <>
      <h1 className="mt-5">HIERARCHIE & KONSENS</h1>
      <br></br>
      {/* <!-- In leichter Sprache --> */}
      <CollapsibleCard
        id="InLeichterSprache"
        title={"In leichter Sprache"}
        content={
          <>
            <p>
              <i><small>*Einordnung* Unsere Gruppe spricht und schreibt schwere Sprache. Wir wollen aber alle Menschen mitmachen lassen. Manche Menschen brauchen leichte Sprache. Wir sind keine Experten in leichter Sprache. Wir haben unsere Texte in leichte Sprache übersetzt. Vielleicht brauchst du leichte Sprache. Wenn du Sätze nicht verstehst bedeutet das nicht, dass du dumm bist. Wenn du Sätze nicht verstehst ist der Text schlecht. Bitte schreibe uns eine E-Mail wenn du Sätze nicht verstehst.
              </small></i>
              <br></br><br></br>
              <h1 className="mt-5">Hierarchien sind das Problem</h1>
              <br></br>
              <i>Was sind Hierarchien?</i><br></br>
              Bei Menschen gibt es oft eine Rangordnung.<br></br><br></br>
              Zum Beispiel:<br></br>
              <ul>
                <li>Manche Menschen können mehr als andere.</li>
                <li>Manche Menschen dürfen mehr als andere.</li>
                <li>Manche Menschen haben mehr Geld als andere.</li>
                <li>Manche Menschen haben weniger Probleme als andere.</li>
                <li>Manche Menschen werden mit mehr Respekt behandelt als andere.</li>
                <li>Manche Menschen haben mehr zu sagen als andere.</li>
              </ul>
              Diese Rangordnung nennt man auch Hierarchie.<br></br><br></br><br></br>
              <i>Warum sind Hierarchien schlecht?</i><br></br>
              Manche Menschen haben also mehr gute Sachen oder weniger schlechte Sachen als andere. Diese Menschen haben Vorteile. Diese Vorteile nennt man auch <b>Privilegien</b>. Ein Mensch mit vielen Privilegien steht weit oben in der Rangordnung. Und ein Mensch, der weit oben in der Rangordnung steht, hat mehr Privilegien als andere. Das bedeutet, dass die Rangordnung immer bestehen bleibt. Menschen, die viele Privilegien haben, werden immer viele Privilegien haben. Und Menschen, die Nachteile haben, werden immer Nachteile haben. Die meisten Menschen unterstützen das. Obwohl die meisten Menschen das gar nicht wollen. Unsere Gesellschaft unterstützt das auch. Obwohl die meisten Menschen in der Gesellschaft das gar nicht wollen. Vielen Menschen fällt es gar nicht auf, weil es diese Rangordnung schon sehr lange gibt.<br></br>
              <br></br>
              Der Wert von Menschen wird oft anhand der Privilegien gemessen. Eine Rangordnung führt also dazu, dass manche Menschen besser gestellt sind als andere. Die Meinung von Menschen die schlechter gestellten sind wird oft nicht gehört. Und sie werden von Problemen stärker getroffen. Das ist ungerecht. Alle Menschen sollen mit Respekt behandelt werden. Es gibt keine besseren oder schlechteren Menschen.<br></br>
              <br></br><br></br>
              <i>Was kann man gegen Hierarchien tun?</i><br></br>
              Wir möchten diese Rangordnungen nicht. Wir versuchen anderen Menschen zu erklären, was Rangordnungen und Privilegien sind. Damit es den Menschen auffällt. Und wir versuchen selber keine Rangordnungen zu untersützen. Oft passiert das, obwohl man das gar nicht will. Wie genau machen wir das?<br></br>
              <br></br>
              Zum Beispiel:<br></br>
              <ul>
                <li>Manche Menschen wissen mehr als andere. Dann können sie anderen Menschen beibringen was sie wissen.</li>
                <li>Manche Menschen können besser reden als andere. Deshalb melden wir uns bei unseren Treffen. Dann können alle Menschen ihre Meiung sagen.</li>
                <li>Manche Menschen haben mehr Zeit als andere. Deshalb schreiben wir Ergebnisse von Treffen immer auf. Bei unseren Treffen gibt es Entscheidungen. Manchmal können Menschen bei den Treffen nicht dabei sein. Diese Menschen fragen wir trotzdem nach ihrer Meinung. Zum Beispiel mit einer Handy-Nachricht. So können alle Menschen bei Entscheidungen mitmachen.</li>
              </ul>
              <br></br>
              <i>Mehrheits-Entscheidung</i><br></br>
              Oft werden Entscheidungen von der Mehrheit getroffen. Das heißt, dass die Meinung der meisten Menschen zählt. Die anderen Meinungen zählen nichts. Dadurch entsteht eine Rangordnung. Diese Form der Entscheidung nennt man auch <b>Mehrheits-Entscheidung</b>. Wir wollen keine Rangordnung haben wenn wir Entscheidungen treffen. Sonst entscheiden nur die Menschen die viel zu sagen haben. Also die Menschen mit vielen Privilegien. Diese Menschen entscheiden natürlich nach ihrer eigenen Meinung. Die Meinung der Menschen mit wenig Privilegien wird nicht gehört. Die Rangordnung bleibt also immer bestehen. Und Menschen, die viele Privilegien haben, werden immer viele Privilegien haben. Oder Menschen, die Nachteile haben, werden immer Nachteile haben.<br></br>
              <br></br><br></br>
              <i>Konsens-Entscheidung</i><br></br>
              Wir möchten die Meinung aller Menschen hören. Für uns gibt es keine besseren oder schlechteren Menschen. Deshalb treffen wir eine Entscheidung nur wenn alle Menschen in unserer Gruppe damit einverstanden sind. Diese Form der Entscheidung nennt man <b>Konsens-Entscheidung</b>. Das ist manchmal schwierig. Und manchmal dauert es lange eine Entscheidung zu finden. Manchmal findet sich auch keine Entscheidung mit der alle zufrieden sind oder es gibt Streit. Dafür gibt es bestimmte Techniken. Diese muss man vorher lernen und einüben. Alle Menschen wissen Dinge die andere Menschen nicht wissen. Verschiedene Menschen sind Experten in verschiedenen Gebieten. Wenn alle Meinungen gehört werden weiß man also mehr über ein Thema. Dann kann man auch bessere Entscheidungen treffen.<br></br>
              <br></br>
              <br></br>
              Wir treffen also Entscheidungen nur wenn alle Menschen in unserer Gruppe damit einverstanden sind. Also eine <b>Konsens-Entscheidung</b>. Dadurch zählt die Meinung aller Menschen. Wir haben also keine Rangordnung in unserer Gruppe. Und wir treffen so bessere Entscheidungen. Denn mit der Entscheidung sind alle Menschen zufrieden. Für uns bedeutet das eine gute Entscheidung. Und ein gutes Zusammen-Leben der Menschen. Alle Menschen sind gleich viel wert. Wir können von anderen lernen. Und wir lernen mit Streit umzugehen. Zusammen sind wir stark.
            </p>
          </>
        }
      />
      <br></br>
      <br></br>
      <div>
        <i>Welche Rolle Hierarchie spielt und wie wir diese abbauen und vermeiden können</i><br></br>
        <br></br>
        <p>
          Der Wert eines Menschen wird oft abhängig von der hierarchischen
          Position wahrgenommen. Dabei stehen Hierarchien in einer engen
          Wechselwirkung mit Privilegien. Das eine bedingt das andere. Die
          hierarchischen Strukturen sind historisch gewachsen und systemisch
          verankert. Individuen verinnerlichen und reproduzieren diese
          unbewusst.
          <br></br>
          <br></br>
          Wir unterscheiden allgemein zwischen Wissens- und Machthierarchien:
          <br></br>
        </p>
        <ul>
          <li>
            Wissenshierarchien entstehen dadurch, dass Menschen unterschiedliche
            Fähigkeiten, Bildungschancen und ungleiche Informationszugänge
            haben.
          </li>
          <li>
            Machthierarchien sind auf Grund historischer Kontinuitäten
            systemisch immanent und werden verstärkt, wenn Wissenshierarchien
            genutzt werden, um Macht über andere auszuüben. Privilegierte
            Menschen haben mehr Mittel zur Verfügung, um ihre eigenen Interessen
            durchzusetzen.
          </li>
        </ul>
        <p>
          <br></br>
          Menschen werden durch gewohnte Hierarchien dazu verleitet, bestehende
          Verhältnisse nicht zu hinterfragen und Verantwortung abzugeben.
          Veränderung setzt aber voraus, dass Menschen diese Machtverhältnisse
          reflektieren und überwinden. Unser Ziel ist es, Wissenshierarchien
          durch Transparenz, Skillshares und Workshops abzubauen, damit daraus
          keine Machthierarchien entstehen können.
          <br></br>
          <br></br>
          Hierarchie ist ein Über- und Unterordnen von Menschen. Sie verhindert
          ein Begegnen auf Augenhöhe und die Selbstwirksamkeit der Individuen.
          Wir lehnen diese Strukturen ab, weil dadurch verschiedenen Menschen
          ein unterschiedlicher Wert beigemessen wird. Ein respektvoller Umgang
          miteinander kann niemals auf Hierarchien basieren. Damit alle Menschen
          einen freien und gleichen Zugang zu Entscheidungsprozessen haben,
          streben wir ein möglichst hierarchiefreies Zusammenleben an. Aus
          unserer Perspektive ermöglichen Konsensentscheidungen die größte
          Freiheit für alle. Gemeinsam als Gruppe Entscheidungen im Konsens zu
          treffen bedeutet, dass diese von allen mitgetragen und Bedenken jede*r
          Einzelnen berücksichtigt werden.
          <br></br>
          <br></br>
          Manchmal kann es schwierig sein, alle unterschiedlichen Interessen und
          Prioritäten unter einen Hut zu bringen. Wenn aber ein Raum entsteht,
          in dem sich alle auf Augenhöhe begegnen, spüren die Menschen eine
          Wertschätzung für ihre Interessen und Bedenken und sind bereit in eine
          gewaltfreie und fruchtbare Kommunikation zu treten. Dieser Prozess
          fördert die Zusammenarbeit und das Verständnis füreinander. Da alle
          Perspektiven berücksichtigt werden, sind kollektive Entscheidungen
          wirksamer und nachhaltiger.
        </p>
      </div>
      <br></br>
      <br></br>
    </>
  );
}
