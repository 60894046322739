import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";

export function Awareness() {
  return (
    <>
      <h1 className="mt-5">AWARENESS</h1>

      <i>Uns ist bewusst, dass wir diese Arbeit nur machen können, weil wir Privilegien haben und diese nutzen können. Daher folgt hier eine Einordnung unserer Gruppe: Weiße, europäisch-sozialisierte Gruppe junger Menschen, mit akademischem Hintergrund, Abled-Bodies, ohne Sprachbarrieren, stabiles und sicheres soziales Umfeld, mit Zugang zu gesellschaftlichen Ressourcen und sozialen Räumen.</i>
      <br></br>
      <br></br>
      {/* <!-- In leichter Sprache --> */}
      <CollapsibleCard
        id="InLeichterSprache"
        title={"In leichter Sprache"}
        content={
          <>
            <p>
              <i><small>*Einordnung* Unsere Gruppe spricht und schreibt schwere Sprache. Wir wollen aber alle Menschen mitmachen lassen. Manche Menschen brauchen leichte Sprache. Wir sind keine Experten in leichter Sprache. Wir haben unsere Texte in leichte Sprache übersetzt. Vielleicht brauchst du leichte Sprache. Wenn du Sätze nicht verstehst, bedeutet das nicht, dass du dumm bist. Wenn du Sätze nicht verstehst, ist der Text schlecht. Bitte schreibe uns eine E-Mail, wenn du Sätze nicht verstehst.
              </small></i>
              <br></br>
              <br></br>
              <i>Wir wissen: Für unsere Gruppe ist es einfacher als für andere, diese Arbeit zu machen. Denn wir sind junge Menschen. Wir sind weiß und in Europa aufgewachsen. Wir sprechen deutsch und haben Zugang zu Bildung.</i><br></br>

              <h1 className="mt-5">Wie wollen wir miteinander umgehen?</h1><br></br>
              Awareness bedeutet: <b>Aufmerksam</b> sein mit sich selbst und anderen.<br></br>
              Achtsam mit allen Menschen umgehen – das ist uns sehr wichtig. <br></br>
              <br></br>
              Damit das funktioniert, darf <b>kein</b> Mensch andere abwerten oder Gewalt ausüben. Dafür setzen wir uns ein. Das Ziel ist, dass alle über sich selbst bestimmen können. Jeder übernimmt Verantwortung für sich selbst. Und jeder übernimmt Verantwortung für andere. Dadurch fühlt sich jeder wohl. Es wird respektiert, was ein Mensch braucht und was er <b>nicht</b> will. Wir wünschen uns: Alle dürfen mitbestimmen und <b>kein</b> Mensch wird ausgeschlossen.
              <br></br><br></br>
              Diskriminierung ist:<br></br>Ein Mensch macht einen anderen Menschen schlecht. Nur weil er andere Merkmale hat, als er selbst.<br></br>
              <br></br>
              Diskriminierung und Gewalt passieren sehr oft. Das liegt an dem Aufbau von unserer Gesellschaft. Menschen werden abgewertet wegen:<br></br>
              <ul>
                <li>ihrer Herkunft</li>
                <li>ihrem Geschlecht</li>
                <li>ihrer Behinderung</li>
                <li>ihrer sexuellen Orientierung</li>
              </ul>
              <br></br>
              Was sie brauchen, ihre Gefühle und Grenzen werden dabei verletzt. Aber sie sind wichtig! Das wollen wir zeigen. Dann ist es für alle einfacher - Jeder Mensch sagt, was er fühlt und braucht.<br></br>
              <br></br>
              Wir wollen aufmerksamer miteinander umgehen. Dann lösen sich Konflikte besser. Und alle erkennen Abwertung und Gewalt schneller. Dadurch verhindern wir vieles davon. Manche Menschen haben mehr Macht und Möglichkeiten als andere. Darum soll jeder Mensch auch seine eigenen Möglichkeiten anschauen. Und sich bewusst machen.<br></br>
              <br></br>
              Wichtig ist auch: <br></br>Alle achten auf sich selbst. Jeder Mensch fühlt, was er braucht. Und fühlt, was er <b>nicht</b> möchte. Und weiß: Das ist für den Umgang mit anderen auch wichtig.<br></br>
              <br></br>
              Darum ist <b>Awareness</b> so wichtig.<br></br>
              <br></br>
              Wir glauben, wenn jeder Mensch…
              <ul>
                <li>…sein Verhalten anschaut</li>
                <li>…über die Folgen von dem Verhalten nachdenkt</li>
                <li>…auf sich achtet</li>
                <li>…auf andere achtet</li>
              </ul>
              …dann können wir gemeinsam etwas verändern. <b>Damit es uns in der Welt miteinander gut geht!</b>
            </p>
          </>
        }
      />
      <br></br>
      <p>
      Für uns ist Awareness der Kern unserer politischen Arbeit. Das bedeutet einen rücksichtsvollen Umgang miteinander aufzubauen und zu etablieren. Wir wollen Diskriminierung, Übergriffen und Gewalt in jeglicher Form entgegentreten und so Räume schaffen, die die Selbstbestimmung jedes einzelnen Menschen stärken. Es geht also darum, Verantwortung für sich und andere zu übernehmen. Dadurch soll eine sichere Atmosphäre entstehen, in der sich grundsätzlich alle wohlfühlen können und persönliche Bedürfnisse und Grenzen respektiert werden. Ziel ist es konsensbasiertes Handeln zu fördern und Strukturen der Ausgrenzung und Ungleichheit abzubauen.
       <br></br>
       <br></br>
       Diskriminierung und Gewalt sind keine losgelösten Einzelfälle, sondern finden in einem strukturellen, gesellschaftlichen Kontext statt. Für viele Menschen sind Abwertungen aufgrund von zugeschriebenen Merkmalen keine Seltenheit, denn unser Alltag ist von patriarchalen, kolonialen, ableistischen und queerfeindlichen Strukturen durchzogen. Diese verhindern, dass Menschen lernen ihre Gefühle, Bedürfnisse und Grenzen überhaupt erfahren zu können und diese dann auszudrücken. 
       <br></br>
       <br></br>
       Wir versuchen auf verschiedenen Ebenen aufmerksamer miteinander umzugehen. Es geht einerseits um die gemeinsame Bearbeitung von Konflikten, andererseits um die Prävention von Diskriminierung, physischer und psychischer Gewalt durch die Benennung von diskriminierenden Strukturen. Dafür ist auch eine Reflexion der eigenen Position in gesellschaftlichen Machtdynamiken notwendig. Und drittens geht es um Selfawarness, also darum, auf sich selbst zu achten, eigene Bedürfnisse und Grenzen wahr und ernst zu nehmen, um dadurch ein nachhaltiges, gewaltfreies Miteinander zu etablieren.
       <br></br>
       <br></br>
       Daher ist Awareness für uns das Herzstück unseres Aktivismus. Denn nur durch eine kontinuierliche Reflexion der eigenen Verhaltensweisen und dem Achten der Bedürfnisse und Grenzen aller Menschen kann Veränderung in Gang gesetzt werden. 
       <br></br>
       <br></br>
       <hr />
       Quellen:<br></br>
       <ul>
        <li><a
          href="https://www.initiative-awareness.de/informieren/awareness"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          https://www.initiative-awareness.de/informieren/awareness
        </a></li>
        <li><a
          href="https://awa-stern.info/grundsatz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          https://awa-stern.info/grundsatz/
        </a></li>
        <li><a
          href="https://www.kommunikationskollektiv.org/wp-content/uploads/2016/04/Zine-Nachhaltiger-Aktivismus.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          https://www.kommunikationskollektiv.org/wp-content/uploads/2016/04/Zine-Nachhaltiger-Aktivismus.pdf
        </a></li>
       </ul>
      </p>
    </>
  );
}
