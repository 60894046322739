import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";

export function NarrativUndZukunftsvision() {
  return (
    <>
      <h1 className="mt-5">NARRATIV & ZUKUNFTSVISION</h1>
      <br></br>
      {/* <!-- In leichter Sprache --> */}
      <CollapsibleCard
        id="InLeichterSprache"
        title={"In leichter Sprache"}
        content={
          <>
            <p>
              <i><small>*Einordnung* Unsere Gruppe spricht und schreibt schwere Sprache. Wir wollen aber alle Menschen mitmachen lassen. Manche Menschen brauchen leichte Sprache. Wir sind keine Experten in leichter Sprache. Wir haben unsere Texte in leichte Sprache übersetzt. Vielleicht brauchst du leichte Sprache. Wenn du Sätze nicht verstehst bedeutet das nicht, dass du dumm bist. Wenn du Sätze nicht verstehst ist der Text schlecht. Bitte schreibe uns eine E-Mail wenn du Sätze nicht verstehst.
              </small></i>
              <br></br>

              <h1 className="mt-5">Probleme und die Zukunft</h1>
              <br></br>
              <i>Wer sind wir und was wollen wir?</i>
              <br></br><br></br>
              Unsere Gruppe setzt sich mit der Klima-Katastrophe auseinander. Wir möchten Teil einer Lösung sein. Die Klima-Katastrophe ist für uns ein Teil von größeren Problemen. Warum gibt es diese Probleme? Weil die Wirtschaft immer weiter wachsen soll. Die Menschen und die Natur werden immer weiter getrennt. Doch Menschen brauchen die Natur zum leben. Menschen leben nicht mehr in starken Gemeinschaften. Sie müssen alleine erfolgreich sein. Und sie müssen sich alleine um ihre Probleme kümmern.<br></br>
              <br></br>
              Die Klima-Katastrophe und die vielen andere Probleme gehen alle etwas an. Sie zu lösen ist eine große Aufgabe. Oft muss man dafür weit in die Zukunft denken. Doch niemand will Verantwortung übernehmen. Manche Menschen haben mehr zu sagen als andere. Menschen die wenig zu sagen haben werden von den Problemen stärker getroffen.<br></br>
              <br></br>
              Unsere Gruppe wollte früher die Politik an ihre Verantwortung erinnern. Dafür sind wir auf Demonstrationen gegangen. Aber die Politik hat nicht auf uns gehört. Andere Probleme wurden schon gelöst. Aber nicht weil die Politik Verantwortung übernommen hat. Sondern weil Menschen wütend waren und ihre Rechte eingefordert haben. So lange und so laut, bis die Probleme gelöst wurden.<br></br>
              <br></br>
              Die Klima-Katastrophe kommt sehr schnell. Und sie ist gefährlich. Wir wollen nicht warten bis die Politik macht, was wir auf Demonstrationen sagen. Deshalb übernehmen wir selber Verantwortung. Wir ändern Dinge die wir jetzt schon selber ändern können. Und wir zeigen anderen Menschen wie sie mit uns mitmachen können. Wir sind wütend und fordern unsere Rechte ein.<br></br>
              <br></br><br></br>
              <i>Wie wollen wir das erreichen?</i>
              <br></br><br></br>
              Die Klima-Katastrophe ist also ein Teil von größeren Problemen. Manche Menschen haben mehr zu sagen als andere. Menschen die wenig zu sagen haben werden von den Problemen stärker getroffen. Die Menschen die mehr zu sagen haben, haben es oft leichter in unserer Welt. Das ist ungerecht. Diese vielen Probleme hängen alle zusammen. Eine Lösung zu finden ist deshalb sehr schwer. Wir denken es braucht einen Neuanfang um die Probleme und Ungerechtigkeit zu beenden. Ein Neuanfang muss auch sein wie wir Sachen bewerten. Für ein gutes Zusammenleben müssen wir folgende Sachen gut bewerten:<br></br>
              <br></br>
              <ul>
                <li>Leben in Gemeinschaft</li>
                <li>Gegenseitige Hilfe</li>
                <li>Respekt und Menschlichkeit</li>
                <li>Wissen über die Natur und die Verletzlichkeit der Natur. Wir sind Teil der Natur und leben von ihr. Darum dürfen wir die Natur nicht kaputt machen.</li>
              </ul>
              <br></br>
              Wie kann dieser Neuanfang passieren? Indem wir selber anfangen so zu leben. Wir müssen diese Sachen wie gute Vorbilder vormachen. Und anderen Menschen helfen. Damit sie auch so leben können.<br></br>
              <br></br><br></br>
              <i>Zusammen sind wir stark</i>
              <br></br><br></br>
              Viele Menschen sind allein, arm oder haben andere Probleme. Diese Menschen haben oft keine Hoffnung mehr. Sie glauben nicht an einen Neuanfang. Aber alle Menschen können dabei mitmachen. Zusammen sind wir stark. Wir können den Neuanfang gemeinsam versuchen. Wir können Menschen mit ihren eigenen Problemen helfen. Dann ist es leichter für sie bei einem Neuanfang mitzumachen. Wenn wir zusammen arbeiten ist es also leichter einen Neuanfang zu schaffen.<br></br>
              <br></br>
              Wir möchten Menschen zeigen, wie sie dabei mitmachen können. Und daurch wieder Hoffnung geben. Wir möchten auch zeigen wie ein Neuanfang ausschauen kann. Das machen wir zum Beispiel so:<br></br>
              <br></br>
              <ul>
                <li><b>Informationen teilen: </b>Wir lernen was die Probleme sind. Diese Informationen wollen wir mit anderen Menschen teilen. Wenn alle wissen was die Probleme sind können alle auch etwas dagegen tun.</li>
                <li><b>Etwas tun: </b>Wir wollen viele neue Leute kennenlernen. Wir wollen wieder eine starke Gemeinschaft aufbauen. In dieser Gemeinschaft sollen alle Menschen leben dürfen. Dort kann man voneinander lernen und sich gegenseitig helfen.</li>
                <li><b>Einen Neuanfang machen: </b>Wir wollen diese Gemeinschaft selber aufbauen. Dadurch können wir anderen Menschen zeigen wie ein Neuanfang aussehen kann. Wir wollen dabei Vorbilder sein. Aber wir wissen dass wir nicht den perfekten Neuanfang machen können. Wir sind alles Menschen und Menschen machen Fehler. Deshalb wollen wir auch von anderen Menschen lernen. Gemeinsam können wir so einen Neuanfang versuchen. Und anderen Menschen das neue Zusammenleben zeigen.</li>
              </ul>
              <br></br>
              Wir wollen einen Neuanfang für alle Menschen machen. Für uns gibt es keine besseren oder schlechteren Menschen. Alle sollen mitmachen können. Dafür haben wir bestimmte Regeln. Zum Beispiel:<br></br>
              <br></br>
              <ul>
                <li>Wir behandeln alle Menschen mit Respekt.</li>
                <li>Es gibt keine schlechteren oder besseren Menschen.</li>
                <li>Wir hören uns zu und lassen uns ausreden.</li>
                <li>Manche Menschen haben mehr zu sagen als andere. Menschen die wenig zu sagen haben werden von den Problemen stärker getroffen. Wir achten darauf, dass bei uns niemand mehr zu sagen hat als andere. Und wir helfen Menschen die mehr Probleme haben. Damit sie genauso viel zu sagen haben wie andere.</li>
                <li>Unsere Regeln gelten nicht nur für Menschen. Unsere Regeln gelten auch für den Umgang mit der Natur. Wir behandeln die Natur mit Respekt. Und wir wissen, dass wir sie nicht kaputt machen dürfen.</li>
              </ul>
            </p>
          </>
        }
      />
      <br></br>
      <div>
        <p>
          <i>Wer wir sind, was wir wollen & warum</i>
          <br></br>
          <br></br>
          Unsere Gruppe setzt sich mit der Klimakatastrophe auseinander und will
          Teil einer Lösung sein. Dabei ist diese Katastrophe unserer Ansicht
          nach nur ein Symptom tiefgreifender Probleme. Hervorgerufen wird sie
          durch eine alles durchdringende Wirtschafts- und Wachstumslogik, die
          sich durch Individualisierung und die Trennung von Mensch und Natur
          ausdrückt. Menschen werden nicht mehr innerhalb von Gemeinschaften
          sozialisiert, sondern von einem individualistischen System geprägt,
          was zu Ignoranz, fehlender Weitsicht und dem Wegschieben von
          Verantwortung führt. Die Auswirkungen treffen aufgrund bestehender
          Hierarchien und daraus resultierender globaler Abhängigkeiten
          gesellschaftlich marginalisierte Menschen besonders hart.
          <br></br>
          <br></br>
          Wir haben gesehen, dass die Politik nicht handeln wird. Jegliche
          Veränderung wurde in der Geschichte durch Druck von unten erkämpft.
          Daher werden wir nicht länger Schilder hochhalten. Wir wollen nicht
          länger den Wind machen, in den die Politik ihre Segel spannt. Wir
          wollen einen Wind erzeugen, der so stark ist, dass es notwendig wird,
          gemeinsam neue, stabilere Segel zu knüpfen - bevor die
          Klimakatastrophe diese von allein zerreißt und uns keine Zeit bleibt,
          neue zu weben.
          <br></br>
          <br></br>
          <br></br>
          Angesichts dieses komplexen Geflechts aus gesellschaftlicher
          Ungleichheit, Machtstrukturen und Diskriminierungssystemen ist keine
          einfache vorgefertigte Lösung möglich. Vielmehr braucht es eine
          Erschütterung, um kapitalistische, patriarchale und koloniale Logiken
          zu durchbrechen und Ungerechtigkeit abzubauen. Grundlage für
          Veränderung ist neben einem Wandel des ökonomischen Systems auch ein
          Wertewandel. Erst durch das Stärken eines allgemeinen Bewusstseins für
          die Endlichkeit unserer planetaren Ressourcen und den Stellenwert der
          Gemeinschaft wird sich auch eine Veränderung unserer Art des
          Wirtschaftens ergeben. Ein solcher radikaler Umschwung kann nur
          gelingen, wenn wir Strukturen schaffen, um andere Formen des
          Zusammenlebens zu ermöglichen.
          <br></br>
          <br></br>
          Viele Menschen fühlen sich machtlos. Sie haben das Gefühl, nichts
          verändern zu können, sei es aufgrund fehlender Ressourcen,
          Abhängigkeiten und Zwängen in festgefahrenen Strukturen oder dem
          Gefühl, allein zu sein. Wir sind aber überzeugt, dass jede*r fähig
          ist, zu handeln und einen Unterschied zu machen. Keine*r ist machtlos
          - gerade Menschen, die auf unterschiedlichen Ebenen diskriminiert und
          unterdrückt werden, schließen sich zusammen und bewegen im Kollektiv
          viel. Dennoch ist es unerlässlich, Ressourcen anders zu verteilen und
          somit die Handlungsmacht aller zu stärken.
          <br></br>
          <br></br>
          Wir als Gruppe wollen Menschen ihre Macht aufzeigen und sie ermutigen,
          aktiv zu werden. So wollen wir der Ohnmacht entgegenwirken und
          Alternativen aufzeigen, die das fehlerhafte System, in dem wir leben,
          ersetzen können. Dazu gehört, durch gezielte Systemkritik einen Bruch
          mit binären Denkweisen und Strukturen herbeizuführen, um neue Wege des
          Handelns zu eröffnen. Kurz gesagt wollen wir informieren, aktivieren
          und revolutionieren:
          <br></br>
        </p>
        <ul>
          <li>
            Informieren: Bildungsangebote, Bewusstsein und Orte der Reflexion
            schaffen
          </li>
          <li>
            Aktivieren: Lokale Strukturen aufbauen, Kollektive bilden, Menschen
            untereinander vernetzen, Synergieeffekte nutzen, Wissen sammeln und
            weitergeben
          </li>
          <li>
            Revolutionieren: Alternativen finden und aufzeigen, neue Formen des
            Zusammenlebens miteinander entwickeln, der Gemeinschaft einen neuen
            Stellenwert geben, neue Strukturen etablieren
          </li>
        </ul>
        <p>
          <br></br>
          Ein gewaltfreier Umgang der Menschen untereinander und mit der Natur
          ist Teil und Ziel dieses Prozesses. Es ist unerlässlich, dass wir dabei 
          stets unsere Position, Grenzen und
          Privilegien reflektieren.
        </p>
      </div>
      <br></br>
      <br></br>
    </>
  );
}
