import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";


export function GlossarUndWissensspeicher() {
  return (
    <>
      <h1 className="mt-5">GLOSSAR</h1>

      <p>
      
        <i>Die meisten Begriffsdefinitionen stammen von uns. Bei Definitionen, die wir von anderen Gruppen übernommen haben, ist natürlich die Quelle angegeben. Wenn Du andere Vorstellungen von bestimmten Begriffen hast, oder einfach nur über bestimmte Begriffe diskutieren möchtest, tritt mit uns in Kontakt.</i>
        <br></br>
        <br></br>

        <b>Autonomie</b>
        <sub>
         Der Begriff stammt aus dem Griechischen und bedeutet "Selbstgesetzgebung". Es bezieht sich auch auf das Recht eines Staates, einer Gruppe oder einzelner Menschen, ihre eigenen Angelegenheiten zu regeln. Individuelle Autonomie bezeichnet die Fähigkeit oder das Recht einer Person, unabhängige Entscheidungen zu treffen und eigenverantwortlich zu handeln. Es umfasst die Selbstbestimmung einer Person, ihre eigenen Werte zu leben, ohne von anderen bevormundet zu werden. Politische Autonomie bezieht sich auf die Selbstverwaltung einer politischen Einheit, die ihre internen Angelegenheiten eigenständig regeln kann. Es ist jedoch wichtig zu beachten, dass Autonomie innerhalb rechtlicher, ethischer und sozialer Grenzen ausgeübt wird und keine absolute Freiheit bedeutet. 
        </sub><br></br><br></br>

        <b>Buen Vivir</b>
        <sub>
         Im Zentrum steht die Idee, dass das Wohlergehen des einzelnen Menschen untrennbar mit dem Wohlergehen der Gemeinschaft und der natürlichen Umwelt verbunden ist. Es geht darum, eine Gesellschaft zu schaffen, die auf Solidarität, Zusammenarbeit und wechselseitiger Anerkennung basiert. Buen Vivir setzt sich dafür ein, dass die Bedürfnisse der Menschen im Einklang mit den Grenzen der Natur stehen. Es zielt auf eine nachhaltige Nutzung der Ressourcen ab und betont den Schutz und die Wiederherstellung der ökologischen Systeme. Das Konzept des Buen Vivir betont auch den respektvollen Umgang mit indigenem Wissen, Traditionen und Sprachen. Es legt großen Wert auf die Anerkennung und Stärkung indigener Gemeinschaften und ihrer Rechte. Buen Vivir dient als inspirierendes Beispiel für eine alternative Lebensweise, die auf dem Prinzip der Harmonie zwischen Mensch und Natur basiert. Buen Vivir wird insbesondere in den Ländern Bolivien und Ecuador als politisches Konzept umgesetzt. Dort ist das Konzept in der jeweiligen Verfassung des Landes verankert. Es wird angestrebt, eine gerechtere und solidarischere Gesellschaft zu schaffen, in der das Wohl der Menschen im Einklang mit der Natur steht. Das Konzept ermutigt uns, über unseren individuellen Wohlstand hinauszudenken und eine Gesellschaft zu schaffen, die auf Kooperation, Gleichberechtigung und dem Schutz der natürlichen Welt beruht.
        </sub><br></br><br></br>

        <b>Dekolonial(isierung)</b>
        <sub>
        beschreibt den Prozess, koloniale Strukturen und Kontinuitäten aufzudecken und diesen entgegenzuwirken. Die Bewegung hat ihren Ursprung in Südamerika und markiert den Beginn des Kolonialismus mit der Ankunft Christoph Kolumbus in den Amerikas. Von dort an wurde der Kolonialismus auf vielen verschiedenen Ebenen etabliert (Alltag-, strukturell -, institutioneller Rassimus) und wirkt sich auf all diesen Ebenen bis heute aus. Die Bewegung versucht Koloniale Strukturen auf Weltmaßstab aufzubrechen und den einzelnen einst kolonialisoerten Ländern ihre Autonomie wieder zurück zu geben. 
        </sub><br></br><br></br>
      
        <b>Grüner Kapitalismus</b>
        <sub>
        bezeichnet den Versuch, Umweltschutz und wirtschaftliche Interessen miteinander in Einklang zu bringen, indem ökologische Nachhaltigkeit als Geschäftsmöglichkeit betrachtet wird. Das Ziel besteht darin, ökonomisches Wachstum und finanziellen Erfolg durch die Integration von Umweltschutzmaßnahmen zu erreichen. Unternehmen sollen dabei sowohl ökologische Probleme lösen als auch weiterhin produzieren, um ihren Erfolg zu garantieren. Dieser Ansatz nutzt den inhärenten Wachstumsgedanken des Kapitalismus, um umweltfreundliche Innovationen zu generieren. Für den*die Einzelverbraucher*in wird grüner Kapitalismus oft in Form von sogenannten klimaneutralen Produkten greifbar, die suggerieren, dass nachhaltiger Konsum die Erde retten kann. 
        </sub><br></br><br></br>

        <b>Postkolonial</b>
        <sub>
        denken und handeln bedeutet, sich rassistischen und kolonialen Strukturen bewusst zu sein. Postkolonialismus besagt nicht, dass wir im Zeitalter "nach" dem Kolonialismus leben, sondern dass der Kolonialismus allgegenwärtig ist und sich auch weiterhin in Institutionen, Strukturen und im Alltag aller Menschen wieder finden lässt und präsent ist. Die Bewegung kommt aus den Literaturwissenschaften und hat ihren Ursprung aus den afrikanischen Perspektiven. Die Versklavung, Ausbeutung und Kapitalisierung des Kontinents ist Teil der andauernden Ausbeutung des Westens dem endlich ein Ende gesetzt werden muss. Es wird versucht die westlichen Strukturen aufzubrechen, um einem gerechteren Dasein aller Menschen zu ermöglichen und darauf aufbauend, eine Rassismuskritische Umgebung aufzubauen.
        </sub><br></br><br></br>

        <b>Privilegien</b>
        <sub>
        bezeichnen besondere Vorteile, die bestimmte Menschen oder Gruppen aufgrund ihrer Eigenschaften genießen. Diese Vorrechte können auf persönlicher, institutioneller oder gesellschaftlicher Ebene existieren und zeigen sich in verschiedenen Bereichen wie Bildung oder Arbeit. Privilegierte Menschen erhalten in der Gesellschaft bevorzugte Behandlung, während andere, die diese Privilegien nicht haben, benachteiligt werden. Dadurch entstehen Ungerechtigkeiten z.B. in Bezug auf den Zugang zu Ressourcen oder die Möglichkeit, Entscheidungen zu beeinflussen. Privilegien sind oft unbewusst oder tief in den Strukturen verankert und können mit verschiedenen Merkmalen wie Geschlecht, Ethnizität, sozialer Klasse, sexueller Orientierung, Alter, religiöse Einstellungen oder Behinderung verbunden sein. Die Anerkennung eigener Privilegien und die Bekämpfung von Diskriminierung sind wichtige Schritte hin zu sozialer Gerechtigkeit. Es erfordert eine kritische Reflexion der eigenen Privilegien sowie den Abbau von Vorurteilen und Diskriminierung, um die gleichen Möglichkeiten und Rechte für alle zu gewährleisten.
        </sub><br></br><br></br>

        <b>Radikal</b>
        <sub>
        heißt "an der Wurzel anpackend". Wenn Menschen radikal handeln und denken, wollen diese das Problem an der Wurzel bearbeiten und nicht nur Symptome bekämpfen. So sind radikale Ansätze oft außerhalb des Systems gedacht, da innerhalb des Systems nur Symptombekämpfung möglich ist. 
        </sub><br></br><br></br>

        <b>Rojava</b>
        <sub>
        ist ein selbstverwaltetes Gebiet im Norden Syriens, das von verschiedenen ethnischen und religiösen Gruppen, vor allem Kurd*innen, bewohnt wird. Es ist ein Beispiel für eine alternative Art des Zusammenlebens, bei der basisdemokratische, feministische und ökologische Prinzipien im Mittelpunkt stehen. Dort spielen die Ideen des demokratischen Konföderalismus eine zentrale Rolle. Das bedeutet, dass die Macht dezentralisiert wird und Gemeinschaften aktiv an politischen Entscheidungen beteiligt sind. Es werden lokale Räte geschaffen, die nach dem Prinzip der direkten Demokratie funktionieren und verschiedene Gruppen vertreten. Zudem ist Rojava für seinen feministischen Ansatz bekannt. Frauen spielen eine wichtige Rolle in allen Bereichen des öffentlichen Lebens und haben eigene Strukturen zur Förderung ihrer Rechte. Rojava legt außerdem großen Wert auf den Schutz der Natur und nachhaltige Entwicklung. Es ist inspirierend zu sehen, wie Rojava zeigt, dass eine gerechtere, demokratischere und solidarische Gesellschaft möglich ist, in der Partizipation, Gleichberechtigung und Umweltverantwortung im Fokus stehen.
        </sub><br></br><br></br>

        <b>Zapatista</b>
        <sub>
        sind eine indigene Bewegung in Mexiko, die sich für soziale Gerechtigkeit, Autonomie und die Rechte indigener Völker einsetzt. Sie entstanden 1994 im Bundesstaat Chiapas und haben ihren Namen von dem Revolutionär Emiliano Zapata erhalten. Die Zapatistas haben sich gegen die Ungerechtigkeiten und Unterdrückung der indigenen Bevölkerung in Mexiko erhoben. Ihr Kampf konzentriert sich auf Landrechte, Selbstbestimmung und kulturelle Anerkennung. Die Bewegung betont gleichberechtigte Organisationsstrukturen, basisdemokratische Entscheidungsfindung und Geschlechtergerechtigkeit.<br></br>
        Was die Zapatistas besonders auszeichnet, ist ihr Einsatz für gewaltlosen Widerstand. Sie setzen auf friedliche Formen des Protests und des Engagements. Zu ihren Aktivitäten gehören Bildungsprogramme, Gesundheitsversorgung, lokale Projekte zur wirtschaftlichen Eigenständigkeit und die Förderung indigener Kultur. Die Zapatistas sind zu einem Symbol des Kampfes indigener Völker und sozialer Bewegungen für Gerechtigkeit und Autonomie geworden. Ihr Kampt für soziale Gerechtigkeit, Autonomie und die Rechte indigener Völker ermutigt uns, weiterhin für unsere Überzeugungen einzustehen.
        </sub><br></br><br></br>
      </p>

      <hr />         
      

      <h1 className="mt-5">WISSENSSPEICHER</h1>
      <ul>
        <li>
         {" "}
         <a
          href="pdf/NA.pdf"
          target="_blank"
          rel="noopener noreferrer"
         >Nachhaltiger Aktivismus
         </a>
           <i>- klimakollektiv.org</i>
        </li>
        <li>
         {" "}
         <a
          href="pdf/Globales Lernen.pdf"
          target="_blank"
          rel="noopener noreferrer"
         >Globales Lernen
         </a>
           <i>- Ein Glossar von Gemeinsam. Gerecht. Global.</i>
        </li>
      </ul> 
      <br></br>
    </>
  );
}
