import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Kontakt() {
  return (
    <>
      <h1 className="mt-5">KONTAKT</h1>
      <p>
        Egal ob Du mal bei einem unserer Plena mit dabei sein möchtest, oder
        über ein konkretes Thema sprechen willst - Setz dich gerne mit uns in
        Verbindung:
        <b></b>
        <br></br>
        <br></br>
        E-Mail: info@kollektivr.de<br></br>
        Instagram: instagram.com/kollektivr/<br></br>
      </p>
      <br></br>
      <hr />

      <h1 className="mt-5">BEFREUNDETE GRUPPEN</h1>
      <p>
        In Augsburg passiert viel. Es gibt einige linke Gruppen, die sich um
        unterschiedliche Themengebiete wie Klimagerechtigkeit, Queer-Feminismus, soziale
        Gerechtigkeit, antirassistische Arbeit und Dekolonialisierung, Wohnraum, Integration und kulturelle
        Vielfalt kümmern. Hier haben wir sie für euch aufgelistet. Jede dieser
        Gruppen freut sich über Unterstützung. Wir kämpfen alle an
        unterschiedlichen Stellen für eine Veränderung dieses Systems. Vielen
        Dank für eure Arbeit und dass ihr weiter kämpft - jeden Tag:<br></br>
        <br></br>
        <a
          href="https://www.endfossilaugsburg.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          End Fossil Augsburg
        </a>
        <br></br>
        <a
          href="https://www.klimacamp-augsburg.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Klimacamp Augsburg
        </a>
        <br></br>
        <a
          href="https://augsburgfueralle.noblogs.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Augsburg für Alle
        </a>
        <br></br>
        <a
          href="https://www.instagram.com/knoblauch.fahne/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Knoblauchfahne
        </a>
        <br></br>
        <a
          href="https://www.instagram.com/obenohnekollektiv/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Oben Ohne Kollektiv
        </a>
        <br></br>
        <a
          href="https://unserhausev.wordpress.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Unser Haus e.V.
        </a>
        <br></br>
        <a
          href="https://paradieschen-augsburg.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Pa*radieschen Augsburg
        </a>
        <br></br>
        <a
          href="https://dieganzebaeckereiaux.blackblogs.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Die ganze Bäckerei
        </a>
        <br></br>
        <a
          href="https://frauenstreikaux.blackblogs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          F*Streik Augsburg
        </a>
        <br></br>
        <a
          href="https://autonomebandeaux.blackblogs.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Autonome Bande Auxburg
        </a>
        <br></br>
      </p>
      <br></br>
      <p>
        Einen tollen Überblick über linke Veranstaltungen und Strukturen in
        Augsburg gibt es bei{" "}
        <a href="https://auxpunks.uber.space/" rel="noreferrer" target="_blank">
          AuxPunks
        </a>
        !
      </p>
      <br></br>
    </>
  );
}
