import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";

export function DemokratieUndMacht() {
  return (
    <>
      <h1 className="mt-5">DEMOKRATIE & MACHT</h1>
      <br></br>
      {/* <!-- In leichter Sprache --> */}
      <CollapsibleCard
        id="InLeichterSprache"
        title={"In leichter Sprache"}
        content={
          <>
            <p>
              <i><small>*Einordnung* Unsere Gruppe spricht und schreibt schwere Sprache. Wir wollen aber alle Menschen mitmachen lassen. Manche Menschen brauchen leichte Sprache. Wir sind keine Experten in leichter Sprache. Wir haben unsere Texte in leichte Sprache übersetzt. Vielleicht brauchst du leichte Sprache. Wenn du Sätze nicht verstehst bedeutet das nicht, dass du dumm bist. Wenn du Sätze nicht verstehst ist der Text schlecht. Bitte schreibe uns eine E-Mail wenn du Sätze nicht verstehst.
              </small></i>
              <h1 className="mt-5">Wie verstehen wir Demokratie und Macht?</h1><br></br>
              Der Klimawandel ist ein großes Problem!<br></br>
              <br></br>
              <ul>
                <li>Das Problem ist überall auf der Welt.</li>
                <li>Das Problem ist schwierig.</li>
                <li>Das Problem betrifft <b>alle Menschen</b>. Wir leiden darunter.</li>
                <li>Menschen in armen Ländern leiden mehr darunter.<i>(Reiche Länder haben auch mehr zu sagen als andere Länder. Deshalb leiden ärmere länder auch mehr unter den Problemen.)</i></li>
                <li>Politiker und Politikerinnen wollen das Problem mit neuer Technologie lösen.</li>
              </ul>
              <br></br>
              Aber:<br></br>
              <ul>
                <li>Technologie hilft der Wirtschaft.</li>
                <li>Technologie hilft dem Reichtum.</li>
                <li>Der Reichtum ist ungerecht verteilt.</li>
              </ul>
              <br></br>
              Das bedeutet:<br></br>
              <ul>
                <li>Technologie macht <b>nur</b> wenige Menschen reicher.</li>
                <li>Technologie braucht Stoffe aus der Natur.</li>
                <li>Technologie macht den Klimawandel schlimmer.</li>
              </ul>
              <br></br>
              Technologie ist <b>keine</b> Lösung. Technologie macht das Problem schlimmer.Es gibt auch andere Lösungen!<br></br>
              <br></br>
              Unser Idee: Wir suchen selbst nach Lösungen.<br></br>
              Denn: Wir haben auch Macht.<br></br>
              <h1 className="mt-5">Aber was bedeutet Macht?</h1><br></br>
              <ul>
                <li>Macht haben bedeutet Möglichkeiten haben.</li>
                <li>Wir können mit Möglichkeiten etwas verändern.</li>
                <li>Wir wollen eine <b>große Veränderung</b>.</li>
                <li>Im Mittelpunkt stehen die Menschen.</li>
                <li>Im Mittelpunkt steht <b>nicht</b> die Wirtschaft.</li>
                <li>Allen Menschen soll es gut gehen.</li>
                <li>Alle Menschen sollen die gleichen Möglichkeiten haben.</li>
              </ul>
              Gerechtigkeit sieht so aus. Menschen müssen für Gerechtigkeit kämpfen.<br></br>
              <br></br><br></br>
              Das funktioniert <b>in Gruppen</b>:<br></br>
              <ul>
                <li>Wir treffen uns.</li>
                <li>Wir reden miteinander.</li>
                <li>Wir reden über Ideen.</li>
                <li>Wir lernen von anderen Menschen.</li>
                <li>Wir suchen nach Lösungen.</li>
              </ul>
              <br></br>
              Zum Beispiel: <br></br>
              Du kannst dich mit deinen Nachbaren und Nachbarinnen treffen. <br></br>
              Du kannst mit ihnen über deine Probleme reden.<br></br>
              Deine Nachbarn haben oft die gleichen Probleme. Ihr könnt zusammen nach Lösungen suchen.<br></br>
              <br></br>
              <br></br>
              <i>Wie entsteht mehr Gerechtigkeit?</i>
              <br></br>
              Wir können uns anschauen, was Menschen in anderen Ländern machen. In anderen Ländern gibt es Menschen, die in Gemeinschaften leben. In den Gemeinschaften gibt es viel Gerechtigkeit. Die Menschen in den Gemeinschaften achten auf die Natur. Die Menschen dort treffen zusammen Entscheidungen. Die Menschen arbeiten zusammen. Alle Menschen haben gleich viel Macht. Alle Menschen dürfen mitentscheiden.<br></br>
              <br></br>
              <b>Wir können auch so leben!</b>
              <br></br><br></br><br></br>
              Das können wir dafür tun:<br></br>
              <ul>
                <li>Wir nehmen verschiedene Meinungen an.</li>
                <li>Wir denken über Gerechtigkeit nach. </li>
                <li>Wir finden heraus, wo es <b>wenig</b> Gerechtigkeit gibt. </li>
                <li>Wir denken über unseren eigenen Vorteile nach.</li>
              </ul>
              <br></br>
              Zum Beispiel:<br></br>
              Ein weißer Mensch hat mehr Vorteile. Schwarze Menschen haben weniger Vorteile. Sie sind wegen ihrer Hautfarbe benachteiligt. Vorteile-haben bedeutet mehr Macht-haben. Und Macht bedeutet mehr Möglichkeiten-haben. Weiße Menschen haben mehr Macht in der Welt. Ein Mensch mit Mehr-Macht kann sie nutzen. Er kann die Macht für Gutes nutzen!
              <br></br><br></br><br></br>
              <i>Was bedeutet Demokratie?</i><br></br>
              Macht ist ungerecht verteilt! Viele Menschen sind benachteiligt. Es gibt verschiedene Formen von Benachteiligung. Zum Beispiel: Rassimus ist die Benachteiligung aufgrund der Hautfarbe. Wir sind benachteiligt, weil jemand sagt, wir sind benachteiligt. Wir sind <b>nicht</b> benachteiligt, weil das zu uns gehört. Menschen <b>ohne</b> Benachteiligung haben mehr Macht. Wir haben <b>weniger</b> Möglichkeiten, wenn wir benachteiligt sind.
              <br></br><br></br>
              Wir wollen, dass Menschen <b>nicht</b> benachteiligt sind. Wir wollen, dass Menschen viele Möglichkeiten haben.
              <br></br><br></br>
              Die Macht in der Welt hängt mit unserer Wirtschaft-Weise zusammen. Die Wirtschafts-Weise hat Einfluss auf unsere Handlungen. Wir haben das Gefühl, dass wir weniger Möglichkeiten haben. Wir haben das Gefühl, dass wir nichts tun können. 
              <br></br><br></br>
              Aber: <br></br>
              Demokratie bedeutet Mit-Machen. Wenn die Menschen nicht mitmachen können, dann ist es keine Demokratie.
              <br></br><br></br><br></br>
              <b>Unsere Wunsch-Demokratie sieht so aus:</b>
              <ul>
                <li>Die Menschen können mitmachen.</li>
                <li>Die Menschen entscheiden zusammen.</li>
                <li>Zusammen entscheiden bedeutet miteinander reden.</li>
                <li>Wir müssen über Regeln, Werte und Ziele reden.</li>
                <li>Alle Menschen haben die gleichen Chancen.</li>
                <li>Alle Menschen haben das gleiche Wissen.</li>
              </ul>
              <br></br>
              Wir nennen das eine <b>Basis-Demokratie</b>. Wir wollen zusammen daran arbeiten!
            </p>
          </>
        }
      />
      <br></br>
      <i>Wie wir Demokratie verstehen und welche Rolle Macht für eine demokratischere Welt spielt</i><br></br>
      <br></br>
      <p>
        Die Klimakatastrophe ist ein globales und komplexes Problem, für das
        hauptsächlich globale Lösungen gefordert werden. Maßnahmen beschränken
        sich aktuell auf technologische Ansätze, um ein 'Weiter so' zu
        ermöglichen, das auf Wirtschaftswachstum und der Sicherung des
        "Wohlstands" basiert. Durch den Fokus der "internationalen Klimapolitik"
        auf globale Ansätze geraten lokale Lösungsstrategien und Fragen der
        sozialen Gerechtigkeit aus dem Blick. Die Menschen haben das Gefühl, ihr
        Handeln mache keinen Unterschied und jede Veränderung sei eine Gefahr
        für den "wohlverdienten Wohlstand". Doch von welchem Wohlstand sprechen
        wir, wenn Ungleichheit stetig wächst und bereits heute Menschen an den
        Folgen der Klimakatastrophe sterben? Scheinbar ist hiermit nur der
        "Wohlstand" des Globalen Nordens gemeint.
        <br></br>
        <br></br>
        <i>Macht</i>
        <br></br>
        Um den Fokus auf Wirtschaftswachstum und den Wohlstand von Wenigen
        aufzubrechen, ist ein grundlegender Wandel nötig. Wenn Menschen etwas in
        der Gesellschaft verändern wollen, müssen sie sich ihrer Macht
        bewusstwerden. Diese nutzen sie, wenn sie bestehende Schwierigkeiten
        erkennen und Alternativen aufzeigen. Sie entfalten Gestaltungspotenzial,
        wenn sie sich treffen und gemeinsam aktiv werden - Stichwort
        Empowerment. Diese Vor-Ort-Initiativen können sogar globale Auswirkungen
        haben. Dafür ist es wichtig, von anderen zu lernen, Ideen auszutauschen
        und die verschiedenen lokalen Ansätze zu vernetzen.
        <br></br>
        <br></br>
        Unserer Ansicht nach ist dabei eine dekoloniale
        <sup>
          <a href="#fussnote1">1 </a>
        </sup> 
          und postkoloniale
         <sup>
          <a href="#fussnote2">2 </a>
        </sup>
          Perspektive unerlässlich. Das bedeutet für uns, über den
        eigenen Erfahrungshorizont hinauszublicken und wahrzunehmen, was bereits
        praktiziert wird. Denn überall auf der Welt gibt es Beispiele, wie ein
        Leben in Gemeinschaft und mit Respekt vor der Natur gelingen kann
        (Rojava & Zapatistas, Buen Vivir).
        <sup>
          <a href="#fussnote3">3 </a>
        </sup>
          Dafür ist Pluralität
        wichtig: Damit alle Menschen gehört werden, müssen bestehende
        Machtstrukturen und historisch geschaffene Ungerechtigkeit - zwischen
        Globalem Norden und Globalem Süden sowie innerhalb einzelner
        Gesellschaften - kritisch hinterfragt werden. Grundlage dafür ist die
        Reflexion unserer eigenen Privilegien. Nur so können wir Strukturen
        verändern und Ressourcen und Zugang zu Macht gerechter verteilten.
        <br></br>
        <br></br>
        <i>Demokratie</i>
        <br></br>
        Momentan ist Macht ungleich verteilt.
        <sup>
          <a href="#fussnote4">4 </a>
        </sup>
          Sie geht hauptsächlich vom neoliberalen
        Paradigma aus, das politische und individuelle Handlungen leitet.
        Festgefahrene Strukturen und zementierte Machtverhältnisse engen den
        Gestaltungsspielraum der Menschen ein. Dieser ist aber ein wesentlicher
        Baustein der Demokratie. Ein politisches System, in dem die Menschen
        keine Partizipationsmöglichkeiten haben, ist keine Demokratie.
        <br></br>
        <br></br>
        Demokratie bedeutet für uns, dass alle Entscheidungskraft von der
        Gemeinschaft ausgeht. Das impliziert einen kollektiven Austausch über
        Prinzipien, Werte und gemeinsame Ziele. Um Partizipationsmöglichkeiten
        zu schaffen, braucht es einen gleichberechtigten Zugang zu
        demokratischen Mitteln, die Verfügbarkeit von Informationen und
        Transparenz von Entscheidungsprozessen. Eine solche gelebte
        Basisdemokratie ist ein Ideal, das aktiv ausgearbeitet und entwickelt
        werden muss. Lasst uns gemeinsam diesen Prozess gestalten!
      </p>
        <br></br>
        <br></br>
        <br></br>
        <hr />
      <p id="fussnote1">
       
       1<sub>
        <a
          href="https://www.kollektivr.net/glossar-wissensspeicher"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Glossar
        </a>
        </sub>
        <br></br>
      </p>

      <p id="fussnote2">
        2<sub>
        <a
          href="https://www.kollektivr.net/glossar-wissensspeicher"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Glossar
        </a>
        </sub>
      </p>

      <p id="fussnote3">
        3<sub>
        <a
          href="https://www.kollektivr.net/glossar-wissensspeicher"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Glossar
        </a>
        </sub>
      </p>

      <p id="fussnote4">
        4<sub>Dies merken wir an der
        andauernden Unterdrückung und Benachteiligung von Menschen,
        beispielsweise in Form von Rassismus, Ableismus, Sexismus, Klassismus
        -und anderer Lebewesen in Form von Speziesismus. Diese
        Ungleichbehandlung ist weder "natürlich" noch "natürlich gewachsen".
        Unterdrückung wird in unserem System reproduziert, um Hierarchien
        aufrecht zu erhalten. Außerdem verhindern und verschleiern
        Unterdrückungsmechanismen unsere Handlungs- und Gestaltungsspielräume.
        Wir können sie dadurch schlecht bzw. gar nicht wahrnehmen. Dies
        erschwert die Auflösung der Unterdrückung und hilft dem System, sich
        selbst zu erhalten. Wir wollen diese Handlungsspielräume wieder sichtbar
        machen und vorantreiben.</sub>
        <br></br>
        
      </p>
      
    </>
  );
}
